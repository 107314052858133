/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Img from 'gatsby-image';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Tag } from '../Tag';
import { Category } from '../Category';
dayjs.extend(utc);

interface Props {
  article: any;
  basePath: string;
}
const ArticleItem = ({ article, basePath }: Props) => {
  const {
    excerpt,
    id,
    author,
    title,
    date,
    category,
    tags,
    featuredImage,

    slug,
  } = article;
  return (
    <article
      sx={{
        display: 'flex',
        flexFlow: ['column', 'row'],
        marginBottom: '30px',
        borderBottom: 'solid 1px',
        borderColor: 'lightBorder',
        paddingBottom: '30px',
        '&:last-of-type': {
          borderBottom: 'none',
        },
      }}
      key={id}
    >
      {featuredImage && (
        <div
          sx={{
            flex: ['0 0 200px', '0 0 250px'],
            height: ['200px', '250px'],
            marginRight: ['0', '30px'],
            marginBottom: ['30px', '0'],
            backgroundColor: '#ccc',
            overflow: 'hidden',
            transition: 'all 0.2s ease',
            border: 'solid 1px',
            borderColor: 'lightBorder',
            ':hover': {
              filter: 'grayscale(50%)',
            },
          }}
        >
          <Link to={`${basePath}/${slug}`} title={title}>
            <Img
              fluid={featuredImage}
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </Link>
        </div>
      )}
      <div
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
        }}
      >
        <div sx={{ marginBottom: '0px' }}>
          <Link to={`${basePath}/category/${_.kebabCase(category)}`}>
            <Category>{category}</Category>
          </Link>
        </div>

        <h2 sx={{ margin: '0' }}>
          <Link to={`${basePath}/${slug}`}>{title}</Link>
        </h2>
        <div>
          <time>{dayjs.utc(date).format('MM/DD/YYYY')}</time>
          {' | '}
          <span sx={{ fontWeight: 600 }}>{author}</span>
        </div>
        <p>{excerpt}</p>
        {tags && (
          <div>
            <FontAwesomeIcon icon={faTags} sx={{ marginRight: '10px' }} />
            {tags.map((tag: any) => (
              <Link key={tag} to={`${basePath}/tag/${_.kebabCase(tag)}`}>
                <Tag>{tag.trim()}</Tag>
              </Link>
            ))}
          </div>
        )}
      </div>
    </article>
  );
};
export default ArticleItem;
